<template>
  <div class="item-box" :style="bgStyle">
    <div :style="widgetStyle">
      <div class="pic-list">
        <div class="img-viewer" :style="images5ModeStyle">
          <NewkiVideo
            class="video-player"
            ref="NewkiVideoRef"
            :url="videoOption.url"
            :block="videoOption.block"
            :seekedStatus="videoOption.seekedStatus"
            :showMuteIcon="true"
            :poster="videoOption.poster"
            :playerOptions="{
              controls: videoOption.controls,
            }"
            @onVideoStatusChange="handleVideoStatusChange"
            v-if="videoOption.url"
          />
          <div class="lg-img" v-else>
            <img :src="lgImageUrl" alt="" v-if="lgImageUrl" />
            <img :src="HolderImage" alt="" v-else />
          </div>
        </div>
        <div class="img-list">
          <template v-for="(item, index) in wData.items">
            <div
              class="img-item"
              :class="{ active: index === activeIndex }"
              @click.stop="onItemClick(index)"
              v-if="item.id"
            >
              <div class="small-img">
                <div class="item-image">
                  <img :src="item.videoCoverPath" alt="" v-if="item.videoCoverPath" />
                  <img :src="HolderImage" alt="" v-else />
                </div>
                <SkipLoop class="loop" :status="videoStatus" v-if="item.id === activeId" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'
import HolderImage from '~/assets/images/empty/normal.svg'
import BestVideoBg from '~/assets/images/content/bestVideo.svg'
// import BestVideoBg from '~/assets/images/content/best-video.png'
import { useStyle } from './renderHooks'

const props = defineProps({
  wData: {
    type: Object,
    required: false,
    default: () => {},
  },
})

// 视频播放动画状态
const videoStatus = ref('')

// 播放器配置
const videoOption = ref({
  url: '',
  poster: '',
  seekedStatus: 'pause',
  controls: false,
  block: true,
})

// 当前激活的 id
const activeId = ref('')

// 播放器 ref
const NewkiVideoRef: any = ref(null)

// 组件样式
const widgetStyle: any = useStyle(props.wData).widgetStyle

// 组件样式
const bgStyle: any = ref({
  backgroundImage: `url(${BestVideoBg})`,
})

const activeIndex: Ref<number> = ref(0)

const images5ModeStyle = ref({
  paddingBottom: '125%',
})

const lgImageUrl = ref(props.wData.extraData.lgImageUrl)

const handleVideoStatusChange = (data: any) => {
  switch (data.eventName) {
    case 'play':
      videoStatus.value = 'play'
      break
    case 'pause':
      videoStatus.value = 'pause'
      break
    case 'ended':
      videoStatus.value = 'ended'
      break
    default:
      break
  }
}

const pick = (item: any) => {
  activeId.value = item?.id
  videoOption.value = {
    url: item?.serverPath,
    poster: item?.diyPath1 || item?.videoCoverPath,
    seekedStatus: 'pause',
    controls: props.wData.controls,
    block: true,
  }
}

// const calcImage5Mode = (wData: any = props.wData) => {
//   if (wData.items.length >= 4) {
//     images5ModeStyle.value = {
//       paddingBottom: '125%',
//     }
//   } else {
//     images5ModeStyle.value = {
//       paddingBottom: '',
//     }
//   }
// }

const onItemClick = async (index: number) => {
  activeViewerImage(index)
  await nextTick()
  NewkiVideoRef.value?.videoReload()
  NewkiVideoRef.value?.doMuted(false)
}

const activeViewerImage = async (index: number = 0) => {
  activeIndex.value = index
  lgImageUrl.value = props.wData.items[index]?.videoCoverPath
  pick(props.wData.items[index])
}

onMounted(() => {
  // calcImage5Mode()
  activeViewerImage()
})
</script>

<style scoped lang="scss">
.item-box {
  position: relative;
  padding: 63px 27px 20px;
  z-index: 10;
  background-size: cover;
  background-position: center center;

  .pic-list {
    padding: 1px;
    @include flex(column, center, center);
    gap: 12px;
    color: initial;

    .img-viewer {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      transition: paddingTop 0.2s ease;
      background-color: #efefef;
      border-radius: 12px;
      overflow: hidden;

      :deep(.newki-video-box) {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;

        img {
          display: inline-block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 12px;
        }
      }
    }

    .img-list {
      @include flex();
      gap: 10px;
      width: 100%;

      .img-item {
        width: calc(25% - 30px / 4);
        padding-bottom: calc((25% - 30px / 4) * 1.25);
        position: relative;
        z-index: 10;
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;
        background-color: #efefef;

        &.active {
          box-shadow: 0 0 2px 2px $primary;
        }

        .small-img {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
          // border: 1px solid #ddd;
          border-radius: 6px;
          overflow: hidden;

          .item-image {
            width: 100%;
            height: 100%;

            img {
              display: inline-block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
:deep(.xgplayer) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
</style>
