import axios from 'axios'
import type { InternalAxiosRequestConfig } from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { stringify } from 'qs'
import { setLoginInfo } from '~/composables/setLoginInfo'
import { useSetMemberInfo } from '~/composables/useSetMemberInfo'
import { showFailToast } from 'vant'
import { cleanLoginInfo } from '~/composables/cleanLoginInfo'
import { routerPush } from '~/composables/routerPush'
import type { IString } from '~/types/IString'
import { getPlatForm } from '@/utils/getPlatForm'

type Result<T> = {
  code: number
  message: string
  result: T
}

type cookiesLanguage = IString | null | undefined

const pendingRequest = new Map()

/*
  自定义的代理的 proxy 名称，需要和 nuxt.config.ts vite.server.proxy 的 path 保持一致
*/
const proxyPath: string = '/proxy'
const proxyPath_yx: string = '/yx'

// 正在刷新標記
let isRefreshing: boolean

// axios 实例
let axiosInstance: AxiosInstance

// axios 实例
let axiosInstance_yx: AxiosInstance

// 开始的时间戳
let startTimestamp: number = 0

// 结束的时间戳
let endTimestamp: number = 0

// 导出Request类，可以用来自定义传递配置来创建实例
export class Request {
  baseURL: string
  // axios 实例
  instance: AxiosInstance
  // 請求隊列
  requests: InternalAxiosRequestConfig[]
  ignoreUrl: string[]

  constructor(baseURL: string, config: AxiosRequestConfig) {
    this.baseURL = baseURL
    this.ignoreUrl = [
      'mall/app/buy/checkManualBillPhone',
      'mall/app/pickUpPoint/check',
      'mall/app/user/matchSite',
      'mall/app/user/login',
      'mall/app/buy/calc',
      'mall/app/buy/buyVip',
      'mall/app/user/setPhone',
      'mall/app/user/setPwd',
      'mall/app/user/refresh',
      'mall/app/user/wxLogin',
      'mall/app/pay/params',
      'goods/app/activity/exchange',
      'goods/app/advertisementPage',
      'biz/visitor/action/save',
      '/aftersale/app/afterSaleOrder/queryApply',
      '/aftersale/app/afterSaleOrder/queryAfterSaleDetail',
    ]
    // 使用axios.create创建axios实例
    this.instance = axios.create(Object.assign({ baseURL: baseURL }, config))
    this.requests = []
    // 請求攔截器
    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        startTimestamp = Date.now()
        config.url = this.serviceReplace(config.url || '')
        const controller = new AbortController()
        // 添加邀請碼
        const newkiInvite = useCookie('nbi').value || useCookie('newki-url-query-ni').value || ''
        if (newkiInvite) {
          config.headers['ni'] = newkiInvite
        }
        // 添加UTM
        const newkiUtm = useCookie('newki-url-query-nu').value || ''
        if (newkiUtm) {
          config.headers['nu'] = newkiUtm
        }
        // 添加站点ID
        const newkiSite = useCookie('newki-site').value || ''
        if (newkiSite) {
          config.headers['Site-Id'] = newkiSite
        }
        // 添加站点Code
        const newkiSiteCode = useCookie('newki-site-code').value || ''
        if (newkiSiteCode) {
          config.headers['Site-Code'] = newkiSiteCode
        }
        // 添加使用语言
        const newkiLanguage = (useCookie('newki-language').value as cookiesLanguage) || {}
        if (newkiLanguage) {
          config.headers['Accept-Language'] = newkiLanguage[newkiSite]
        }
        // 添加使用平台
        config.headers['platform'] = getPlatForm()
        // 添加token
        const token = useCookie('token')
        if (token.value) {
          config.headers.Token = token.value
        }
        // 添加token
        const ftofs_grey = useCookie('ftofs_grey')
        if (ftofs_grey.value) {
          config.headers.ftofs_grey = ftofs_grey.value
        }
        config.signal = controller.signal
        // 判斷是否重複請求
        const requestKey = this.generateReqKey(config)
        if (pendingRequest.has(requestKey)) {
          controller.abort()
        }
        pendingRequest.set(requestKey, '')
        return config
      },
      (err: any) => {
        // 授權失敗，登錄過期
        if (err.response && err.response.status === 401) {
          this.refreshingToken(err.config)
        }
        const message = this.errorDeal(err.response.status)
        showFailToast(message)
        return Promise.reject(err)
      }
    )

    // 響應攔截器
    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        this.removePendingRequest(res.config)
        if (this.isNoToastUrlList(res.config)) {
          return res.data
        }

        if (res.data.success || res.data.code === 200) {
          return res.data
        } else {
          // 後端返回錯誤統一處理為服務異常，請稍後再試
          if (res.data.msg && res.data.msg !== '系统异常：null') {
            showFailToast(res.data.msg)
          } else {
            showFailToast('服務異常！請稍後再試~')
          }

          // 处理自定义的 API 信息捕获
          endTimestamp = Date.now()
          const { $analyze }: any = useNuxtApp()
          const error = {
            url: res.config.url,
            method: res.config.method,
            rc: res.status,
            rt: endTimestamp - startTimestamp,
            traceid: res.data.requestId,
            req_body: JSON.parse(res.config.data),
            req_query: '',
          }
          $analyze.newkiApiTrace(error)
          return Promise.reject()
        }
      },
      (err: any) => {
        this.removePendingRequest(err.config)
        if (!err.response) {
          return Promise.reject(err)
        }
        // 授權失敗，登錄過期
        if (err.response.status === 401) {
          if (err.config.url === 'mall/app/user/logout') {
            // 登出接口不做處理
            cleanLoginInfo()
            routerPush('index')
          } else {
            this.refreshingToken(err.config)
          }
        } else if (String(err.response.status).includes('50') && !String(err.response.status).includes('502')) {
          showError({
            statusCode: err.response.status,
            statusMessage: `Status Code: ${err.response.status}`,
            fatal: true,
          })
        } else {
          const message = this.errorDeal(err.response.status)
          showFailToast(message)
        }
        return Promise.reject(err)
      }
    )

    if (!import.meta.env.SSR) {
      let counter: number | string | null = sessionStorage?.getItem('AxiosCounter')
      counter === null ? (counter = 0) : (counter = Number(counter) + 1)
      sessionStorage?.setItem('AxiosCounter', String(counter))
    }
  }

  // 定义请求方法
  public request(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return this.instance.request(config)
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Result<T>>> {
    const newkiSite = useCookie('newki-site').value || ''
    if (import.meta.env.SSR || newkiSite == null || newkiSite == '') {
      return this.severFetch(url, 'get', config, '')
    } else {
      return this.instance.get(url, config)
    }
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<Result<T>>> {
    const newkiSite = useCookie('newki-site').value || ''
    if (import.meta.env.SSR || newkiSite == null || newkiSite == '') {
      return this.severFetch(url, 'post', config, data)
    } else {
      return this.instance.post(url, data, config)
    }
  }

  public formPost<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<Result<T>>> {
    const header: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
    const newkiSite = useCookie('newki-site').value || ''
    if (import.meta.env.SSR || newkiSite == null || newkiSite == '') {
      return this.severFetch(url, 'formPost', config, data)
    } else {
      return this.instance.post(url, stringify(data), Object.assign(header, config))
    }
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<Result<T>>> {
    return this.instance.put(url, data, config)
  }

  public delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<Result<T>>> {
    return this.instance.delete(url, config)
  }

  private isNoToastUrlList(config: InternalAxiosRequestConfig): boolean {
    // 對動態 url 做特殊處理，比如 /goods/app/advertisementPage/20231114001
    const urlList = [
      'goods/app/advertisementPage/',
      'member/app/memberInfo/email',
      'member/app/memberAddress/get',
      'goods/app/arrivalNotice/get',
      'mall/app/buy/buyStep1',
    ]
    let noToast = false
    for (let i = 0; i < urlList.length; i++) {
      if (config.url?.includes(urlList[i])) {
        noToast = true
        break
      }
    }
    if (this.ignoreUrl.includes(config.url!) || noToast) {
      return true
    } else {
      return false
    }
  }

  private errorDeal(code: number): string {
    // 这里用来处理http常见错误，进行全局提示
    let message: string
    switch (code) {
      case 200:
        message = ''
        break
      case 400:
        message = '請求錯誤(400)'
        break
      case 401:
        message = '未授權，請重新登錄(401)'
        // 这里可以做清空storage并跳转到登录页的操作
        break
      case 403:
        message = '拒絕訪問(403)'
        break
      case 404:
        message = '請求出錯(404)'
        break
      case 408:
        message = '請求超時(408)'
        break
      case 500:
        message = '服務器錯誤(500)'
        break
      case 501:
        message = '服務未實現(501)'
        break
      case 502:
        message = '網絡錯誤(502)'
        break
      case 503:
        message = '服務不可用(503)'
        break
      case 504:
        message = '網絡超時(504)'
        break
      case 505:
        message = 'HTTP版本不支持(505)'
        break
      default:
        message = `鏈接出錯!`
    }
    return message
  }

  private async refreshingToken(config: InternalAxiosRequestConfig) {
    this.requests.push(config)
    if (!isRefreshing) {
      isRefreshing = true
      const token = useCookie('refresh_token')
      if (!token.value) {
        cleanLoginInfo()
        routerPush('login')
      }
      // 添加站点ID
      const newkiSite = useCookie('newki-site').value || ''
      // 添加使用语言
      const newkiLanguage = (useCookie('newki-language').value as cookiesLanguage) || {}
      const urlReplace = this.serviceReplace(':mall/app/user/refresh')
      const data = await useFetch(`${this.baseURL}${urlReplace}`, {
        method: 'post',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Site-Id': newkiSite,
          'Accept-Language': newkiLanguage[newkiSite],
        },
        body: `token=${token.value}`,
      })
      const result: any = data.data.value
      if (result.success) {
        await setLoginInfo(result.data)
        await useSetMemberInfo()
        window.location.reload()
        // setTimeout(() => {
        //   isRefreshing = false
        //   this.requests.forEach(cb => this.instance.request(cb))
        //   // 已经刷新了token，将所有队列中的请求进行重试
        //   this.requests = []
        // }, 5000)
      } else {
        showFailToast(result.msg)
        cleanLoginInfo()
        routerPush('login')
      }
    }
  }

  private generateReqKey(config: InternalAxiosRequestConfig) {
    const { method, url, params, data } = config
    let temp = ''
    if (data && typeof data === 'object') {
      temp = stringify(data)
    } else if (data && typeof data === 'string') {
      try {
        temp = stringify(JSON.parse(data))
      } catch (e) {
        temp = data
      }
    }
    return [method, url, stringify(params), temp].join('&')
  }

  private removePendingRequest(config: InternalAxiosRequestConfig) {
    const requestKey = this.generateReqKey(config)
    if (pendingRequest.has(requestKey)) {
      pendingRequest.delete(requestKey)
    }
  }

  // 替換url的服務部分，約定替換以:開頭的，到第一個/的部分，替換成appConfig配置的服務名
  private serviceReplace(url: string): string {
    const serviceConfig = useAppConfig().service
    if (!url) {
      return ''
    }
    if (!url.startsWith(':')) {
      return url
    } else {
      const temp = url.split('/')
      const replace = temp[0].substring(1, temp[0].length)
      // @ts-ignore
      if (serviceConfig[replace]) {
        // @ts-ignore
        temp[0] = serviceConfig[replace]
        return temp.join('/')
      } else {
        return url
      }
    }
  }

  /**
   * 服務器端獲取
   */
  private async severFetch(url: string, method: string, config: any, postData: any) {
    const route = useRoute()
    const data = await $fetch('/api/nocookie', {
      params: {
        data: JSON.stringify({
          url: route.fullPath,
          api: this.serviceReplace(url || ''),
          method: method,
          baseurl: this.baseURL,
          postData: postData,
          theParams: config?.params,
          token: useCookie('token').value,
        }),
      },
    })
      .then(res => {
        return res
      })
      .catch(err => {
        return null
      })
    return unref(data)
  }
}

// 获取 axios 的实例
const getRequestInstance = (url: string, config: AxiosRequestConfig<any>) => {
  if (!axiosInstance) {
    // @ts-ignore
    axiosInstance = new Request(url, config)
  }
  return axiosInstance
}

// 获取 axios 的实例
const getRequestInstance_yx = (url: string, config: AxiosRequestConfig<any>) => {
  if (!axiosInstance_yx) {
    // @ts-ignore
    axiosInstance_yx = new Request(url, config)
  }
  return axiosInstance_yx
}

export function $axios() {
  // 獲取運行時配置
  const runtimeConfig = useRuntimeConfig()
  // axios config 配置
  const config = { withCredentials: true }
  let baseURL: string = runtimeConfig.public.apiBase
  if (import.meta.env.MODE === 'development' && !import.meta.env.SSR) {
    baseURL = proxyPath
  }
  // return new Request(baseURL, {})
  return getRequestInstance(baseURL, config)
}

export function $axios_yx() {
  // 獲取運行時配置
  const runtimeConfig = useRuntimeConfig()
  // axios config 配置
  const config = { withCredentials: true }
  let baseURL: string = runtimeConfig.public.imApiUrl
  if (import.meta.env.MODE === 'development' && !import.meta.env.SSR) {
    baseURL = proxyPath_yx
  }
  // return new Request(baseURL, {})
  return getRequestInstance_yx(baseURL, config)
}
