<template>
  <div class="bottom-toolbar-container">
    <van-cell-group inset>
      <van-field
        class="text-area"
        v-model="message"
        rows="1"
        :autosize="{ maxHeight: 100 }"
        type="textarea"
        :placeholder="t('common.saySomething')"
      />
    </van-cell-group>
    <div class="toolbar">
      <van-uploader
        :multiple="true"
        :max-count="9"
        :before-read="beforeRead"
        :after-read="afterRead"
        accept="image/*,video/*"
      >
        <NewkiSvg class="toolbar-icon" name="picture-fill" :color="primary.default" :size="32" />
      </van-uploader>
      <NewkiSvg class="toolbar-icon" name="send-fill" :filled="true" :size="32" @click="sendMsg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import NewkiSvg from '~/components/NewkiSvg.vue'
import { useColorsStore } from '~/store/colors'
import { imageUpload } from '~/service/api/chat'
import { showFailToast } from 'vant'
import { ref, watch } from 'vue'
import { useMemberStore } from '~/store/member'
import { useImStore } from '~/store/im'
import isEmpty from 'lodash/isEmpty'
import { useNanoid } from '~/composables/useNanoid'
import dayjs from 'dayjs'

const { t } = useI18n()
const { primary } = storeToRefs(useColorsStore())
const { $im }: any = useNuxtApp()
const message = ref('')
const { memberInfo } = storeToRefs(useMemberStore())
const { specialMessage } = storeToRefs(useImStore())
const { clearSpecialMessage, setNewMessage } = useImStore()
// 上傳標記
const uploadingFlag = ref(false)
const maxImageNum = 9
const maxImageSize = 1024 * 1024 * 10
const maxVideoSize = 1024 * 1024 * 40

watch(
  () => specialMessage.value,
  val => {
    if (!isEmpty(val)) {
      const code = val.autoReplyCode ? val.autoReplyCode : ''
      if (code) {
        delete val.autoReplyCode
      }
      send([val], code)
      clearSpecialMessage()
    }
  },
  { deep: true }
)

function sendMsg() {
  if (!message.value) {
    showFailToast(t('chat.sendEmpty'))
    return
  }
  const list = message.value.split('\n')
  let messageList = []
  for (let i = 0; i < list.length; i++) {
    messageList[i] = {
      type: 'text',
      content: useXss(list[i]),
    }
  }
  send(messageList)
}

function sendImg(url: string, type: string = 'image') {
  const msg = {
    type: type === 'image' ? 'image' : 'video',
    content: url,
  }
  send([msg])
}

// code 自動回復消息的code
function send(data: any[], code: string = '') {
  const uuid = useLocalStorage('nk-chat', '')
  const params = {
    msgChannelCode: 'newki',
    msgChannelName: 'newki',
    msgChannelType: 2, // 1-FB 2-Newki 3-whatsApp
    msgType: 1, // 1-咨詢 2-服務 3-系統
    msgSourceType: code ? 3 : 6, // 消息來源类型，1友現客服、2友現監控、3友現自動化、4原生客服、5原生自動化、6訪客、7AI
    msgStatus: 4, // 1-未讀 2-已讀 3-失敗 4-未知
    msgVisitorCode: memberInfo.value.memberId ? memberInfo.value.memberId : uuid.value,
    msgVisitorName: memberInfo.value.name ? memberInfo.value.name : t('common.visitor'),
    msgContent: JSON.stringify(data),
    msgVisitorAvatar: memberInfo.value.avatar || '',
    msgVisitorPhone: memberInfo.value.phone || '',
    autoMsgTemplateCode: code,
    uuid: useNanoid(12),
  }
  $im.send(params)
  // 這裡先加入聊天列表, 待socket返回，通過對比UUID進行替換
  setNewMessage({
    lastMsgType: params.msgType,
    lastMsgTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    lastMsgContent: params.msgContent,
    uuid: params.uuid,
    loading: true,
  })
  message.value = ''
}

// 圖片上傳前校驗
function beforeRead(file: any) {
  console.log(file)
  let fileList = []
  let flag = true
  if (file.length) {
    fileList = file
  } else {
    fileList = [file]
  }
  // 數量
  if (fileList.length > maxImageNum) {
    showFailToast({
      message: t('fileUpload.numError', { num: maxImageNum }),
    })
    flag = false
  }
  fileList.forEach((item: any) => {
    if (item.type.includes('video')) {
      // 大小
      if (item.size > maxVideoSize) {
        showFailToast({
          message: t('fileUpload.sizeError', { size: maxVideoSize / 1024 / 1024 }),
        })
        flag = false
        return false
      }
      // 類型
      const typeList = ['mp4', 'mov', 'avi', 'mkv', '3gp', 'wmv', 'webm']
      const temp = item.name.split('.')
      const type = temp[temp.length - 1].toLowerCase()
      if (!typeList.includes(type)) {
        showFailToast({
          message: t('fileUpload.typeError', { type: 'mp4,mov,avi,mkv,3gp,wmv,web' }),
        })
        flag = false
        return false
      }
    } else {
      // 大小
      if (item.size > maxImageSize) {
        showFailToast({
          message: t('fileUpload.sizeError', { size: maxImageSize / 1024 / 1024 }),
        })
        flag = false
        return false
      }
    }
  })
  return flag
}

// 圖片讀取後回調
async function afterRead(file: any) {
  let fileList: any[] = []
  if (file.length) {
    fileList = file
  } else {
    fileList = [file]
  }
  fileList.forEach((item: any) => {
    item.status = 'uploading'
    item.message = t('fileUpload.uploading')
  })
  uploadingFlag.value = true
  for (let i = 0; i < fileList.length; i++) {
    const formData = new FormData()
    formData.append('imgFile', fileList[i].file)
    let data = await imageUpload(formData)
    if (data.code !== 200) {
      showFailToast(t('fileUpload.failed'))
    } else {
      sendImg(data.msg, fileList[i].file.type.includes('video') ? 'video' : 'image')
    }
  }
  uploadingFlag.value = false
}
</script>

<style scoped lang="scss">
.bottom-toolbar-container {
  width: 100%;
  margin-top: 8px !important;
  display: flex;
  justify-content: space-between;
  padding: 9px 12px;
  background-color: $white;

  .toolbar {
    display: flex;
    flex-shrink: 0;
    align-items: flex-end;

    .toolbar-icon {
      margin-right: 20px;
      &:hover {
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    :deep(.nuxt-icon svg) {
      margin-bottom: 0;
    }
  }

  :deep(.van-field__control) {
    @extend .text-head-regular;
    color: $text-color-assist;
  }
}
:deep(.van-cell-group--inset) {
  margin: 0;
  flex-grow: 1;
  margin-right: 12px;
}
:deep(.van-cell) {
  margin: 0;
  background-color: $bg-color;
  padding: 8px 10px;
  border-radius: 18px;
}
</style>
