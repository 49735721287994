<template>
  <div
    v-if="recommendList?.length"
    class="recommend-container"
    :style="{ backgroundColor: `${props.mode === 'light' ? '#fff' : bg}` }"
  >
    <div class="slogan">
      <img height="34" src="~/assets/images/common/slogan-v2.svg" alt="slogan" />
    </div>
    <div class="line">
      <img src="~/assets/images/recommend/line.svg" alt="line" />
    </div>
    <div class="recommend-content" v-if="recommendList?.length">
      <VueFlexWaterfall class="water-fall-container" align-content="center" col="2" col-spacing="16">
        <div
          class="recommend-goods"
          v-for="(item, index) in recommendList"
          :key="index"
          :style="{ borderColor: `${colorList[index % 7]}` }"
          @click="goDetail(item?.skuId)"
        >
          <NewkiImage
            :src="getGoodsImg(item?.listPictureVideo, item?.mainPictureVideo)"
            :block="true"
            :width="320"
            :height="320"
            :rules="{ newkiId: 'Recommend', q: 100 }"
            :alt="item?.goodsName"
          />
          <div class="recommend-main">
            <div class="intro" v-if="item?.marketingProgram">
              <span class="intro-text text-truncate">{{ item?.marketingProgram }}</span>
              <div class="intro-bg"></div>
            </div>
            <div class="goods-name text-body">{{ item?.goodsName }}</div>
            <!-- <van-text-ellipsis class="goods-name text-body" :content="item?.goodsName" rows="2" /> -->
            <div
              class="goods-price"
              :class="{
                'goods-price-column':
                  !memberInfo?.payMember && item?.memberPrice && item?.marketPrice !== item?.memberPrice,
              }"
            >
              <template v-if="memberInfo?.payMember && item?.memberPrice && item?.marketPrice !== item?.memberPrice">
                <div v-if="item.supportPresell == '1'" class="presell-text">{{ t('common.newAndPresell') }}</div>
                <VipPrice class="vip-price" :price="item?.memberPrice" small v-else />
              </template>
              <template v-else>
                <div v-if="item.supportPresell == '1'" class="presell-text">{{ t('common.newAndPresell') }}</div>
                <NewkiPrice
                  class="market-price"
                  :price="item?.marketPrice"
                  :bold="true"
                  :priceSize="17"
                  :curreySize="13"
                  :priceLineHeight="16"
                  v-else
                />
                <!-- <NewkiBetterPriceTag
                  v-if="!memberInfo?.payMember && item?.memberPrice && item?.marketPrice !== item?.memberPrice"
                /> -->
              </template>
            </div>
            <div
              class="price-origin-box"
              v-if="
                memberInfo?.payMember &&
                item?.memberPrice &&
                item?.marketPrice !== item?.memberPrice &&
                item.supportPresell != '1'
              "
            >
              <span class="price-origin">
                {{ t('promotion.originPrice') }}
              </span>
              <NewkiPrice :price="item?.marketPrice" :price-size="13" :currey-size="13" :color="textAssist" />
            </div>
            <div v-if="item?.promotionDesc && item?.promotionDesc.length" class="tag-container">
              <NewkiTag
                v-for="(item, index) in item?.promotionDesc"
                class="tag-item"
                :key="index"
                :text="item"
                type="promotion"
              />
            </div>
          </div>
        </div>
      </VueFlexWaterfall>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiGetRecommend } from '~/service/api/search'
import { useColorsStore } from '~/store/colors'
import type { IGoodsItem } from '~/types/ISearch'
import { VueFlexWaterfall } from 'vue-flex-waterfall'
import { useMemberStore } from '~/store/member'

const props = defineProps({
  mode: {
    type: String,
    required: false,
    default: 'dark',
    validator(value: string) {
      return ['light', 'dark'].includes(value)
    },
  },
})

const { bg, textAssist } = storeToRefs(useColorsStore())
const { memberInfo } = storeToRefs(useMemberStore())
const colorList = ref<string[]>(['#F87171', '#FB923C', '#FACC15', '#4ADE80', '#22D3EE', '#60A5FA', '#C084FC'])
const recommendList = ref<IGoodsItem[]>([])
const { t } = useI18n()
// 服務端調用
const { data } = await useAsyncData(`apiGetRecommend`, () => apiGetRecommend(), {
  lazy: true,
  default: () => ({
    recommendList: [],
  }),
})
watch(
  data,
  val => {
    if (val) {
      recommendList.value = val
    }
  },
  { immediate: true }
)

function filterVideo(list: string[]) {
  return list.filter(item => !item.includes('.mp4'))
}

// 計算商品顯示的圖片
function getGoodsImg(listPic: string[], mainPic: string[]) {
  const list = filterVideo(listPic || [])
  const main = filterVideo(mainPic || [])
  return list[0] ? list[0] : main[0]
}

// 跳去商品詳情
// todo contentID 暫時沒有，之後需要在補
function goDetail(skuId: string) {
  routerPush('product-skuId-contentId', { params: { skuId: +skuId } })
}
</script>

<style scoped lang="scss">
.recommend-container {
  width: 100%;
  padding: 15px 0 0;

  .slogan {
    @include flex();
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    img {
      width: 45%;
    }
  }

  .line {
    text-align: center;

    img {
      width: 100%;
    }
  }

  .recommend-content {
    width: 100%;
    display: flex;
    background-image: url(@/assets/images/recommend/bg.png);
    background-repeat: repeat-y;
    background-size: 100% auto;
    justify-content: center;
    padding-bottom: 100px;

    .water-fall-container {
      width: 100%;
      box-sizing: content-box !important;
    }

    :deep(.van-image) {
      border-radius: 16px 16px 0px 0px !important;
    }

    .recommend-main {
      padding: 0 10px 10px 10px;
    }

    .recommend-goods {
      @include cursor();
      width: 44%;
      background-color: $white;
      border: 1.5px solid transparent;
      border-radius: 16px;
      margin-bottom: 16px;
      overflow: hidden;

      .intro {
        position: relative;
        margin: 4px 0 0 0;
        display: inline-block;
        box-sizing: border-box;
        max-width: 136px;

        .intro-text {
          width: 100%;
          font-weight: 700;
          color: $primary;
          position: relative;
          z-index: 1;
          line-height: 1;
          display: inline-block;
          padding: 0 4px;
        }
        .intro-bg {
          width: 100%;
          height: 9px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 99px;
          background-color: $primary-50;
          z-index: 0;
        }
      }

      .goods-name {
        @include line(2);
        width: 100%;
        color: $text-color-default;
        margin-top: 4px;
        position: relative;
      }
    }

    .goods-price {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 8px;

      .market-price {
        margin-top: 8px;
        margin-right: 8px;
      }
      // .vip-price {
      //   margin-top: 8px;
      // }
      .presell-text {
        color: $presell-price;
        font-weight: bold;
        font-size: 17px;
        line-height: 1;
        padding-bottom: 2px;
      }
    }
  }
}
.goods-price-column {
  align-items: flex-start !important;
  flex-direction: column;
  gap: 4px;
}
.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  gap: 8px 10px;

  // .tag-item {
  //   margin-top: 10px;
  //   margin-right: 10px;
  // }
}
.price-origin-box {
  margin-top: 4px;
  .price-origin {
    padding-right: 4px;
    font-size: $text-sm;
    color: $text-color-assist;
  }
}
</style>
