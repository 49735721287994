<template>
  <van-form ref="formRef" class="login-form" @submit="login">
    <AreaCodeInput @validPhone="validPhone" />
    <SmsInput
      ref="smsInput"
      :is-valid-phone="isValidPhone"
      @agreementShake="agreementShake"
      @login="validateForm"
      type="login"
    />
    <GraphicCodeInput v-if="errorNum > 5" />
    <div class="btn-container">
      <van-button v-if="!isLogin" class="login-btn" round block type="primary" native-type="submit">
        {{ t('login.login') }}
      </van-button>
      <van-button v-else class="login-btn" round block type="primary" loading :loading-text="t('login.logging')" />
      <van-button class="other-login-btn" round block @click="toPwdLogin">
        {{ t('login.loginPwd') }}
      </van-button>
    </div>
  </van-form>
  <div class="agreement-container" :class="{ twinkle: reminderCheck }">
    <van-checkbox v-model="isAgreeAgreement" />
    <div class="text-content">
      <div>{{ t('login.loginTip') }}</div>
      <div>
        {{ t('login.loginAgreement')
        }}<span class="agreement-title" @click="openArticle('register')">{{ t('login.ServiceAgreement') }} </span
        ><span class="agreement-title" @click="openArticle('privacy')">{{ t('login.PrivacyAgreement') }}</span>
      </div>
    </div>
  </div>
  <NewkiArticle v-if="scena.length" ref="articleRef" :scena="scena"></NewkiArticle>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import AreaCodeInput from '~/components/login/AreaCodeInput.vue'
import SmsInput from '~/components/login/SmsInput.vue'
import GraphicCodeInput from '~/components/login/GraphicCodeInput.vue'
import { usePopupStore } from '~/store/popup'
import { useMemberStore } from '~/store/member'
import { useSystemStore } from '~/store/system'
import { showToast } from 'vant'
import type { FormInstance } from 'vant'
import { login as loginApi } from '~/service/api/login'
import type { ILogin } from '~/types/ILogin'
import { useLocalStorage } from '@vueuse/core'
import NewkiArticle from '~/components/NewkiArticle.vue'
import { Article } from '~/enum/article'
import { useGetBetterPrice } from '~/store/getBetterPrice'
import { SmsCodeEnum } from '~/enum/SmsCode'

const props = defineProps({
  isDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const emit = defineEmits(['pwdLogin', 'setPwd', 'cancel'])
const { t } = useI18n()
// 是否同意用戶協議
const { isAgreeAgreement, areaCode, mobile } = storeToRefs(useMemberStore())
// 當前站點
const { currentSite } = storeToRefs(useSystemStore())
const { setPopupNode } = usePopupStore()
const smsInput = ref<InstanceType<typeof SmsInput> | null>(null)
const reminderCheck = ref(false)
const formRef = ref<FormInstance>()
const articleRef = ref<InstanceType<typeof NewkiArticle> | null>(null)
const scena = ref<any[]>([])
const { setGetMoreFlag, setPageCode } = useGetBetterPrice()
// 手機號是否驗證通過
let isValidPhone = ref(false)
// 正在登錄
let isLogin = ref(false)
// 登錄錯誤次數
let errorNum = useLocalStorage('login-error', 0)

const validPhone = (flag: boolean) => {
  isValidPhone.value = flag
}

watch(isAgreeAgreement, val => {
  if (val) {
    reminderCheck.value = false
  }
})

onMounted(() => {
  const { $analyze }: any = useNuxtApp()
  $analyze.beginLogin()
})

function validateForm() {
  let arr = ['areaCodeInput']
  if (errorNum.value > 5) {
    arr.push('graphicCodeInput')
  }
  formRef.value
    ?.validate(arr)
    .then(() => {
      login()
    })
    .catch()
}

const login = async () => {
  // 判斷是否同意用戶協議
  if (!isAgreeAgreement.value) {
    showToast(`${t('login.agreementTip')}${t('login.ServiceAgreement')}${t('login.PrivacyAgreement')}`)
    agreementShake()
    return false
  }
  isLogin.value = true

  let data: ILogin = await loginApi({
    areaCode: areaCode.value,
    phone: mobile.value,
    code: smsInput.value?.smsCode,
    siteId: currentSite.value,
    operationType: SmsCodeEnum.login,
  })
  isLogin.value = false
  if (data) {
    setLoginInfo(data)
    await useSetMemberInfo()
    if (data.newMember) {
      // 新用户跳去密码设置
      setPopupNode('register')
      analyze(data.authInfo.memberId)
      if (!props.isDialog) {
        routerReplace('setPwd')
      } else {
        emit('setPwd')
      }
      // setGetMoreFlag(true)
      // setPageCode('RegisterGetVip')
    } else {
      analyzeLogin(data.authInfo.memberId)
      if (!props.isDialog) {
        const nkOriginUrl = useLocalStorage('nk-origin-url', '')
        if (nkOriginUrl.value) {
          const data = JSON.parse(nkOriginUrl.value)
          const router = useRouter()
          router.replace({
            name: data.name,
            query: data?.query,
            params: data?.params,
          })
          nkOriginUrl.value = ''
        } else {
          routerBack()
        }
      } else {
        emit('cancel')
      }
    }
    const timer = setTimeout(() => {
      clearTimeout(timer)
      setPopupNode('login')
    }, 500)
  } else {
    smsInput.value?.cleanCode()
  }
}

function analyze(memberId: string) {
  const { $analyze }: any = useNuxtApp()
  $analyze.register('sms', memberId)
}

function analyzeLogin(memberId: string) {
  const { $analyze }: any = useNuxtApp()
  $analyze.login('sms', memberId)
}

const toPwdLogin = () => {
  if (!props.isDialog) {
    routerReplace('pwdLogin')
  } else {
    emit('pwdLogin')
  }
}

const agreementShake = () => {
  // 未同意用戶協議添加抖動特性
  reminderCheck.value = true
  const timer = setTimeout(() => {
    clearTimeout(timer)
    reminderCheck.value = false
  }, 2000)
}

function openArticle(type: string) {
  if (type === 'register') {
    scena.value = [Article.registerAgreement]
  } else {
    scena.value = [Article.privacyAgreement]
  }
  const timer = setTimeout(() => {
    clearTimeout(timer)
    articleRef.value?.open()
  })
}
</script>

<style scoped lang="scss">
.login-form {
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;

  .btn-container {
    margin-top: 32px;
    padding: 0 35px;

    .login-btn {
      border-bottom: 4px solid $secondary;
    }

    .other-login-btn {
      margin-top: 20px;
    }
  }
}
.agreement-container {
  width: 100%;
  margin-top: 24px;
  padding: 4px 0;
  display: flex;
  justify-content: center;

  .text-content {
    margin-left: 6px;
    font-size: $text-xs;
    color: $text-color-assist;

    .agreement-title {
      color: $primary;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.twinkle {
  animation: twinkle 1s infinite ease-in-out;
}

@keyframes twinkle {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #e6f7fd;
  }
  100% {
    background-color: transparent;
  }
}
</style>
